import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes/router';
import type { FC } from 'react';
import React, { useMemo, useState } from 'react';

import { AdvertisementBankCard } from '@/components';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { useCmsContent } from '@/contexts/CmsContentContext';
import FREEDOM_LOGO from '@/public/assets/partners/freedom24/logo.png';
import { useSearchStore } from '@/store';
import { getTerm, prepareSearchQueryToStore } from '@/store/storeSearch.utils';
import type { IBankDepositCard } from '@/types';
import { AnalyticsEvent } from '@/types/analytics';
import { capitalizeFirstLetter, cn } from '@/utils';
import { getFreedom24DirectUrl } from '@/utils/advertisements/getFreedom24DirectUrl';
import { getFixedDepositsBreadcrumbs } from '@/utils/breadcrumbs';
import { findCountryKey } from '@/utils/countries';
import { parseTermKeysFromUrl } from '@/utils/termKeys';

import { DepositProtection } from '../DepositProtection';
import { SkeletCard } from '../SkeletCard';
import { DepositCards, FeaturedDepositCards } from './parts';

interface DepositsCardListProps {
  featured: IBankDepositCard[];
  allDeposits: IBankDepositCard[];
  onLoadMoreDeposits?: () => Promise<void>;
  onLoadMoreFeaturedDeposits?: () => Promise<void>;
  // eslint-disable-next-line react/no-unused-prop-types
  favoriteDeposits?: IBankDepositCard[];
  shouldLoadMore?: boolean;
  shouldLoadMoreFeatured?: boolean;
  className?: string;
  depositCardsClassName?: string;
  shouldRenderH1?: boolean;
}
// TODO: CHANGE PLACEMENT: Should be migrated to the /src/layouts/DepositsLayout/DepositsLayout/parts
export const DepositsCardList: FC<DepositsCardListProps> = ({
  featured,
  allDeposits,
  onLoadMoreDeposits,
  onLoadMoreFeaturedDeposits,
  shouldLoadMore = true,
  shouldLoadMoreFeatured = true,
  className,
  depositCardsClassName,
  shouldRenderH1,
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { country: initialCountry } = useCmsContent();

  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [isMoreFeaturedLoading, setIsMoreFeaturedLoading] = useState(false);

  const { isLoading } = useSearchStore((state) => state);

  const loadMoreDeposits = async () => {
    try {
      setIsMoreLoading(true);
      if (onLoadMoreDeposits) {
        await onLoadMoreDeposits();
      }
    } finally {
      setIsMoreLoading(false);
    }
  };

  const loadMoreFeaturedDeposits = async () => {
    try {
      setIsMoreFeaturedLoading(true);
      if (onLoadMoreFeaturedDeposits) {
        await onLoadMoreFeaturedDeposits();
      }
    } finally {
      setIsMoreFeaturedLoading(false);
    }
  };

  // TODO: variant with request
  // const isFavorited = (deposit: IBankDepositCard): boolean => {
  //   return favoriteDeposits.some(
  //     (favorite) => favorite.productIndex === deposit.productIndex,
  //   );
  // };

  const query = prepareSearchQueryToStore(router.query);

  const getTermForH1 = () => {
    if (!router.query.term) return '';
    const lTerm = parseTermKeysFromUrl(router.query.term as string);
    if (lTerm && lTerm.length === 1) {
      return getTerm(lTerm.toString(), t, false, true).toLowerCase();
    }
    return '';
  };

  const generateH1 = () => {
    return t('deposits.h1', {
      term: getTermForH1(),
      country: t(
        `database.countries.${
          capitalizeFirstLetter(
            findCountryKey(router.query.country as string),
          ) || initialCountry.name
        }`,
      ),
      currency:
        query.currency === 'all'
          ? ''
          : (router?.query?.currency as string)?.toUpperCase() || 'EUR',
    });
  };

  const shouldShowFreedomAd = useMemo(() => {
    const c =
      capitalizeFirstLetter(findCountryKey(router.query.country as string)) ||
      initialCountry.name;
    return c !== 'Germany';
  }, [initialCountry, router.query.country]);

  return (
    <div className={cn('mt-2 flex w-full flex-col items-center', className)}>
      {shouldShowFreedomAd && (
        <AdvertisementBankCard
          bankName="FREEDOM24"
          annualFee={t('savings-accounts.annual-fee.free')}
          rate={7.08}
          image={FREEDOM_LOGO.src}
          comment={t('freedom24.comment')}
          brokerUrl="/brokers/freedom24"
          cashback={null}
          className="mb-3 mt-4 w-full"
          country="Cyprus"
          primaryColor="#16A34A"
          directUrl={getFreedom24DirectUrl(router.locale || 'en')}
          blockViewEvent={AnalyticsEvent.freedom24BlockView}
          openOnlineEvent={AnalyticsEvent.freedom24ClickOpenOnline}
          shareEvent={AnalyticsEvent.freedom24ClickShare}
        />
      )}
      <Breadcrumbs
        breadcrumbs={getFixedDepositsBreadcrumbs()}
        className="mt-4 self-start"
      />
      {shouldRenderH1 && (
        <div className="mt-6 flex w-full justify-start">
          <h1 className="h5 text-left">{generateH1()} 🔥</h1>
        </div>
      )}
      <DepositProtection />
      {!isLoading ? (
        <>
          {featured && featured.length !== 0 ? (
            <FeaturedDepositCards
              isLoading={isMoreFeaturedLoading}
              featured={featured}
              onLoadMore={loadMoreFeaturedDeposits}
              shouldLoadMore={shouldLoadMoreFeatured}
            />
          ) : null}
          {allDeposits && allDeposits.length !== 0 && (
            <DepositCards
              isLoading={isMoreLoading}
              deposits={allDeposits}
              onLoadMore={loadMoreDeposits}
              shouldLoadMore={shouldLoadMore}
              className={depositCardsClassName}
            />
          )}
        </>
      ) : (
        <div className="flex flex-col gap-4 py-5">
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <SkeletCard key={index} />
            ))}
        </div>
      )}
    </div>
  );
};
