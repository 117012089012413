import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import type { FC } from 'react';
import { useInView } from 'react-intersection-observer';

import { SavingsAccountsTerms } from '@/components/BankCard/parts/SavingsAccountsTerms';
import { ShareButton } from '@/components/ShareButton/ShareButton';
import type { AnalyticsEvent } from '@/types/analytics';
import { AnalyticsCategory } from '@/types/analytics';
import { analyticServices, cn } from '@/utils';

import { BadgeDeposit } from '../BadgeDeposit';

type BankCardProps = {
  bankName: string;
  image: string;
  country: string;
  comment: string;
  rate: number;
  annualFee: string | null;
  cashback: string | null;
  className?: string;
  isBlogBankCard?: boolean;
  brokerUrl?: string;
  directUrl: string;
  primaryColor?: string;
  maintananaceFee?: number;
  blockViewEvent: AnalyticsEvent;
  openOnlineEvent: AnalyticsEvent;
  shareEvent: AnalyticsEvent;
};

export const AdvertisementBankCard: FC<BankCardProps> = ({
  bankName,
  image,
  country,
  comment,
  rate,
  annualFee,
  cashback,
  className,
  isBlogBankCard,
  brokerUrl,
  directUrl,
  primaryColor,
  maintananaceFee,
  blockViewEvent,
  openOnlineEvent,
  shareEvent,
}) => {
  const { t } = useTranslation();

  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0.5,
    onChange: (inView) => {
      if (inView) {
        analyticServices.sendEvent({
          action: blockViewEvent,
          category: AnalyticsCategory.savingsAccountsList,
        });
      }
    },
  });

  const handleClickOpenOnline = () => {
    analyticServices.sendEvent({
      action: openOnlineEvent,
      category: AnalyticsCategory.savingsAccountsList,
    });
  };

  const handleClickShare = () => {
    analyticServices.sendEvent({
      action: shareEvent,
      category: AnalyticsCategory.savingsAccountsList,
    });
  };

  return (
    <div
      ref={ref}
      className={cn(
        `border-[#44C9C7] border-2 relative flex max-w-full flex-col rounded-xl bg-white`,
        className,
      )}
      style={{
        borderColor: primaryColor ? `${primaryColor}` : '',
      }}
    >
      <div className="body3 top-0 -mt-2 ml-[14px] flex flex-wrap gap-1 font-semibold md:absolute md:left-[22px] md:ml-0 md:mt-0 md:-translate-y-1/2 md:gap-2">
        <BadgeDeposit variant="special" bgColor={primaryColor} />
        <BadgeDeposit
          variant="protected"
          text={t('deposit_card.protected_up_to_value', { value: '€20K' })}
        />
        <BadgeDeposit
          variant="free-maintenance"
          text={
            maintananaceFee
              ? t('deposit_card.start_from_value', {
                  value: `€${maintananaceFee}`,
                })
              : undefined
          }
        />
      </div>

      <div
        className={cn(
          'flex-1 px-4 pb-4 pt-[20px] md:px-6 lg:pt-[24px] xl:pb-3 xl:pt-7',
        )}
      >
        <div
          className={cn(
            'flex justify-between',
            isBlogBankCard && 'xl:justify-normal',
          )}
        >
          <div
            className={cn(
              'md:justify-between xl:w-full xl:min-w-[320px] xl:max-w-[370px]',
              isBlogBankCard && 'xl:max-w-[280px] xl:min-w-[280px]',
            )}
          >
            {brokerUrl ? (
              <Link href={brokerUrl}>
                <div className="flex gap-2 md:gap-3">
                  <Image
                    src={image}
                    // alt={bank.productIndex.toString()}
                    alt="bankImage"
                    width={64}
                    height={64}
                    loading="lazy"
                    className={cn(
                      'h-6 w-6 rounded-full border bg-blue-900/10 min-[375px]:h-[38px] min-[375px]:w-[38px] md:h-[40px] md:w-[40px] lg:h-[56px] lg:w-[56px] xl:h-[64px] xl:w-[64px]',
                      isBlogBankCard && 'xl:h-14 xl:w-14',
                    )}
                  />
                  <div className="flex max-w-sm flex-col leading-tight">
                    <div className="flex flex-col text-start text-black">
                      <div className="md:hidden">
                        <p
                          className={cn(
                            'line-clamp-2 min-w-[140px] max-w-[140px] text-[15px] font-bold capitalize leading-[20px] tracking-[0.2px] text-blue-900 md:line-clamp-none md:min-w-[200px] md:max-w-[200px] xl:min-w-[260px] xl:max-w-[260px] xl:text-[18px] xl:font-semibold xl:leading-[24px] xl:tracking-[0.1px]',
                            isBlogBankCard &&
                              'md:min-w-[180px] md:max-w-[180px] lg:min-w-[200px] lg:max-w-[200px] xl:min-w-[220px] xl:max-w-[220px]',
                          )}
                        >
                          {bankName}
                        </p>
                      </div>
                      <p
                        className={cn(
                          'line-clamp-2 hidden md:block min-w-[140px] max-w-[140px] text-[15px] font-bold capitalize leading-[20px] tracking-[0.2px] text-blue-900 md:line-clamp-none md:min-w-[200px] md:max-w-[200px] xl:min-w-[260px] xl:max-w-[260px] xl:text-[18px] xl:font-semibold xl:leading-[24px] xl:tracking-[0.1px]',
                          isBlogBankCard &&
                            'md:min-w-[180px] md:max-w-[180px] lg:min-w-[200px] lg:max-w-[200px] xl:min-w-[220px] xl:max-w-[220px]',
                        )}
                      >
                        {bankName}
                      </p>
                      <p className="body3 text-grey-600">
                        {t(`database.countries.${country}`)}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ) : (
              <div className="flex gap-2 md:gap-3">
                <Image
                  src={image}
                  // alt={bank.productIndex.toString()}
                  alt="bankImage"
                  width={64}
                  height={64}
                  loading="lazy"
                  className={cn(
                    'h-6 w-6 rounded-full border bg-blue-900/10 min-[375px]:h-[38px] min-[375px]:w-[38px] md:h-[40px] md:w-[40px] lg:h-[56px] lg:w-[56px] xl:h-[64px] xl:w-[64px]',
                    isBlogBankCard && 'xl:h-14 xl:w-14',
                  )}
                />
                <div className="flex max-w-sm flex-col leading-tight">
                  <div className="flex flex-col text-start text-black">
                    <div className="md:hidden">
                      <p
                        className={cn(
                          'line-clamp-2 min-w-[140px] max-w-[140px] text-[15px] font-bold capitalize leading-[20px] tracking-[0.2px] text-blue-900 md:line-clamp-none md:min-w-[200px] md:max-w-[200px] xl:min-w-[260px] xl:max-w-[260px] xl:text-[18px] xl:font-semibold xl:leading-[24px] xl:tracking-[0.1px]',
                          isBlogBankCard &&
                            'md:min-w-[180px] md:max-w-[180px] lg:min-w-[200px] lg:max-w-[200px] xl:min-w-[220px] xl:max-w-[220px]',
                        )}
                      >
                        {bankName}
                      </p>
                    </div>
                    <p
                      className={cn(
                        'line-clamp-2 hidden md:block min-w-[140px] max-w-[140px] text-[15px] font-bold capitalize leading-[20px] tracking-[0.2px] text-blue-900 md:line-clamp-none md:min-w-[200px] md:max-w-[200px] xl:min-w-[260px] xl:max-w-[260px] xl:text-[18px] xl:font-semibold xl:leading-[24px] xl:tracking-[0.1px]',
                        isBlogBankCard &&
                          'md:min-w-[180px] md:max-w-[180px] lg:min-w-[200px] lg:max-w-[200px] xl:min-w-[220px] xl:max-w-[220px]',
                      )}
                    >
                      {bankName}
                    </p>
                    <p className="body3 text-grey-600">
                      {t(`database.countries.${country}`)}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex gap-4">
            <SavingsAccountsTerms
              interestRate={rate}
              isInterestRateUpTo
              annualFee={annualFee}
              cashback={cashback}
            />
            <div
              className={cn(
                'hidden gap-2 lg:flex lg:gap-4 shrink-0',
                isBlogBankCard && 'xl:gap-3 xl:pl-[212px]',
              )}
            >
              <a
                className={cn(
                  '!text-white body3-bold xl:body2-bold flex max-h-[44px] min-h-[44px] min-w-[126px] items-center justify-center rounded-full bg-[#44C9C7] px-6 text-center hover:bg-[#36adab] xl:max-h-[56px] xl:min-h-[56px] xl:min-w-[160px]',
                  isBlogBankCard &&
                    'xl:min-w-[126px] xl:min-h-[44px] xl:max-w-[126px] xl:body3-bold xl:px-4 xl:max-h-[44px] text-[13px]',
                )}
                style={{
                  backgroundColor: primaryColor ? `${primaryColor}` : '',
                }}
                onClick={handleClickOpenOnline}
                href={directUrl}
                target="_blank"
              >
                {t('deposit_card.button_open_online')}
              </a>
              <ShareButton
                value={directUrl}
                onSuccess={handleClickShare}
                shouldAddDomain={false}
              />
            </div>
          </div>
        </div>
        <div
          className={cn(
            'mt-3',
            'flex flex-col ml-12 md:ml-[52px] xl:ml-[78px] border-blue-100 lg:ml-[54px]',
            comment && 'md:border-none lg:border-none',
          )}
        >
          <div className="flex justify-between border-y border-blue-100 pb-[12px] pt-[10px] md:hidden">
            <p className="body3 text-grey-600">{t('global.annual_fee')}</p>
            <p className="body3-bold text-blue-900">{annualFee}</p>
          </div>
          <div className="flex justify-between pb-[12px] pt-[10px] md:hidden">
            <p className="body3 text-grey-600">{t('global.cashback')}</p>
            <p className="body3-bold text-blue-900">
              {cashback
                ? t(`savings-accounts.cashback.${cashback}`)
                : t(`savings-accounts.cashback.no`)}
            </p>
          </div>
          {comment &&
            comment.split('\n').map((item, index) => (
              <div
                className="relative flex items-center border-t border-blue-100 pb-[12px] pt-[10px]"
                key={index}
              >
                <span className="absolute -left-3 size-1 rounded-full bg-blue-500" />
                <p className="body3 w-full font-normal tracking-[0.1px]">
                  {item}
                </p>
              </div>
            ))}
          <div
            className={cn('lg:hidden flex items-center justify-end gap-2 mt-3')}
          >
            <a
              className={cn(
                '!text-white w-full body3-bold xl:body2-bold flex max-h-[44px] min-h-[44px] min-w-[126px] items-center justify-center rounded-full bg-[#44C9C7] px-6 text-center hover:bg-[#36adab] xl:max-h-[56px] xl:min-h-[56px] xl:min-w-[160px]',
                isBlogBankCard &&
                  'xl:min-w-[126px] xl:min-h-[44px] xl:max-w-[126px] xl:body3-bold xl:px-4 xl:max-h-[44px] text-[13px]',
              )}
              style={{
                backgroundColor: primaryColor ? `${primaryColor}` : '',
              }}
              onClick={handleClickOpenOnline}
              href={directUrl}
              target="_blank"
            >
              {t('deposit_card.button_open_online')}
            </a>
            <ShareButton
              value={directUrl}
              className="cursor-pointer border-none hover:text-pink-500"
              onSuccess={handleClickShare}
              shouldAddDomain={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
